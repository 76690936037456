var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "form-modal"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.isValidForm
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Mutasikan")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Dari Kas"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.currentKas ? _vm.currentKas.nama : '',
      "disabled": ""
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Pilih Tujuan Kas"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.kasOptions,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.ke_kas,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ke_kas", $$v);
      },
      expression: "form.ke_kas"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2",
    attrs: {
      "label": "Nominal"
    }
  }, [_c('b-form-input', {
    staticClass: "mb-1",
    on: {
      "keyup": function keyup($event) {
        _vm.form.nominal = _vm.formatRupiah(_vm.form.nominal);
      }
    },
    model: {
      value: _vm.form.nominal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nominal", $$v);
      },
      expression: "form.nominal"
    }
  }), _vm.currentKas ? _c('i', {
    staticClass: "text-danger"
  }, [_c('strong', [_vm._v("Maksimal Mutasi: Rp " + _vm._s(_vm.formatRupiah(_vm.currentKas.saldo)))])]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }